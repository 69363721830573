<!--
 * @Description: 支付
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-14 22:00:00
 * @FilePath: /JinChanTuoKe-Front/src/components/payment/index.vue
-->
<template>
  <div class="payment-popup" @click="shows = false">
    <div class="payment" @click.stop>
      <div class="payment-text">请选择支付渠道并付款</div>
      <div class="payment-ewm">
        <img src="../../assets/icon/ewm.png" alt="" />
      </div>
      <!-- <div class="payment-list">
        <div class="payment-item">
          <div class="payment-item-icon">
            <img src="../../assets/icon/zfb.png" alt="" />
          </div>
          <div class="payment-item-mask">
            <div class="mask-ewm"></div>
            <div class="mask-foot">支付宝扫一扫付款</div>
          </div>
        </div>
        <div class="payment-item">
          <div class="payment-item-icon">
            <img src="../../assets/icon/wx.png" alt="" />
          </div>
          <div class="payment-item-mask" style="border: 2px solid #00b700">
            <div class="mask-ewm"></div>
            <div class="mask-foot" style="background-color: #00b700">
              微信扫一扫付款
            </div>
          </div>
        </div>
        <div class="payment-item">
          <div class="payment-item-icon">
            <img src="../../assets/icon/yl.png" alt="" />
          </div>
          <div class="payment-item-mask" style="border: 2px solid #082f67">
            <div class="mask-ewm"></div>
            <div class="mask-foot" style="background-color: #082f67">
              欢迎使用银联卡
            </div>
          </div>
        </div>
      </div> -->
      <div class="payment-btn" @click="shows = false">我已付款去上传凭证</div>
      <div class="payment-close" @click="shows = false">
        <img src="../../assets/icon/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    data: {
      type: Object,
    },
  },
  computed: {
    shows: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit("update:show", newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  .payment {
    width: calc(516px);
    height: calc(430px + 152px);
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    &-text {
      font-size: 30px;
      color: #000000;
      text-align: center;
      margin-top: 35px;
    }
    &-ewm {
      width: 240px;
      margin: auto {
        top: 20px;
      }
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    &-list {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 45px;
      .payment-item {
        margin-right: 45px;
        &:last-child {
          margin-right: 0;
        }
        &-icon {
          height: 43px;
          width: 148px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: 90%;
          }
        }
        &-mask {
          width: 146px;
          height: calc(32px + 144px);
          border: 2px solid #00a0e9;
          margin-top: 23px;
          border-radius: 12px;
          overflow: hidden;
          position: relative;
          .mask-ewm {
            width: 116px;
            height: 116px;
            background-color: #eee;
            margin: auto {
              top: 14px;
            }
          }
          .mask-foot {
            width: 100%;
            height: 32px;
            background: #00a0e9;
            text-align: center;
            line-height: 32px;
            position: absolute;
            font-size: 12px;
            color: #fff;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }
      }
    }
    &-btn {
      width: 272px;
      height: 37px;
      background: #4e6bef;
      border-radius: 8px;
      margin: auto {
        top: 47px;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
    }
    &-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
