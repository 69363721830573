<!--
 * @Description: 签署合同
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-14 22:09:56
 * @FilePath: /JinChanTuoKe-Front/src/components/sign/index.vue
-->
<template>
  <div class="sign-popup" @click="shows = false">
    <div class="sign" @click.stop>
      <div class="sign-text">签署合同</div>
      <div class="sign-tag">
        套餐基本信息：<span>{{ data.title }}</span>
      </div>
      <div class="sign-content">
        <div>
          云鼎汇富（ABoostify） AI营销云平台用户服务协议<br />
          欢迎您使用金蝉拓客！<br />

          本协议是用户（“您”）与云鼎汇富（ABoostify）
          AI营销云平台（简称"本站"，https://www.aboosify.com/）所有者（以下简称为"ABoostify
          AI营销云平台"）之间就Boostify下的产品服务“金蝉拓客”(以下简称金蝉拓客)相关事宜所订立的契约，在使用金蝉拓客服务前，请您务必充分阅读并理解本协议的全部内容，其中限制、免责条款或者其他涉及您重大权益的条款（如违约处罚、争议管辖等）可能会以加粗、加下划线等形式提示您重点注意。<br />

          除非您已充分阅读、理解并同意接受和遵守本协议，否则，请勿购买或使用金蝉拓客服务。
          您点击"同意并继续"按钮或您通过网页确认或以其他任何方式明示或者默示表示接受本协议，或您以任何方式继续使用金蝉拓客服务的，即视为您已阅读并同意接受本协议的约束，本协议即在您与ABoostify
          AI营销云平台之间产生法律效力。<br />

          使用金蝉拓客服务应当遵守本协议及其服务规则。 服务规则以ABoostify
          AI营销云平台官网或其他相关页面展示的内容为准，您应事先了解服务规则，并根据服务规则进行操作，以确保顺利使用。<br />

          第一条 总则<br />
          1.1 金蝉拓客服务指ABoostify
          AI营销云平台提供的基于抖音等短视频平台的SEO服务，您可以根据自己的需求选择使用并遵守其服务规则。<br />
          1.2
          涉及具体服务的服务内容、服务等级、技术规范、操作文档、计费标准等内容的服务条款、规则、说明、标准等（统称为“服务规则”），以ABoostify
          AI营销云平台官网或其他相关页面展示的内容为准。<br />
          1.3 ABoostify
          AI营销云平台是中立的技术服务提供者，依约向您提供各类技术产品和服务；您的网站、应用、软件、平台等任何产品、服务及相关内容等，由您自行运营并承担全部责任。<br />
          1.4
          双方均保证已经依照国家相关规定获得了合法经营资质或政府审批等，有权依法运营其产品及服务。双方进一步保证，在本协议有效期内持续保持具备国家相关规定要求的经营资质或审批手续。<br />

          第二条 账号<br />
          2.1 注册<br />
          2.1.1 注册者资格<br />
          1）您确认，在您完成注册程序或以其他ABoostify
          AI营销云平台允许的方式实际使用本服务时，您应当是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织（以下统称为“法律主体”）。<br />
          2）若您不具备完全民事权利能力和行为能力，则您不具备前述主体资格，您及您的监护人应承担因您的不当注册行为而导致的一切后果，且ABoostify
          AI营销云平台有权注销（永久冻结）您的账户，并向您及您的监护人索偿。<br />
          2.1.2 注册、账户和实名认证<br />
          （1）
          在您按照注册页面提示填写信息、阅读并同意本服务条款且完成全部注册程序后，或在您按照激活页面提示填写信息、阅读并同意本服务条款且完成全部激活程序后，或您以其他ABoostify
          AI营销云平台允许的方式实际使用本网站服务时，您即受本服务条款约束。
          您可以使用您提供或确认的用户名、手机号码或者ABoostify
          AI营销云平台允许的其它方式作为登录手段进入本网站。<br />
          （2）
          您了解并同意，您在本网站完成的注册程序并注册成功后，您即已获得ABoostify
          AI营销云平台账户。<br />
          （3） 目前 ABoostify AI营销云平台允许一个法律主体拥有多个ABoostify
          AI营销云平台账户，但一个ABoostify
          AI营销云平台账户仅能对应唯一的法律主体。除非有法律规定或生效法律文书确定，或者符合ABoostify
          AI营销云平台公布的条件，否则您不得以任何方式转让、赠与或让他人继承您的ABoostify
          AI营销云平台账户。同时，在进行符合条件的ABoostify
          AI营销云平台账户转让、赠与或继承时，ABoostify
          AI营销云平台有权要求您、及/或受让受赠者、或您的继承人提供合格的文件材料并按照ABoostify
          AI营销云平台要求的操作流程办理。<br />
          （4） 您了解并同意，为保证账户和交易安全，ABoostify
          AI营销云平台有权随时要求您完成ABoostify
          AI营销云平台账户的实名认证；同时，ABoostify
          AI营销云平台可能会就某些产品或服务的开通，要求您提供更多的身份资料和信息，做进一步的身份认证或资格验证，您的账户只有在通过这些认证和验证之后，方可获得使用相关产品或服务的资格。<br />
          （5） 信息<br />
          您应本着诚实信用、合法善意的原则向ABoostify
          AI营销云平台提交信息及资料，相关信息及资料不得违反法律法规、公序良俗，不应提交任何违法、虚假或不良信息。<br />
          在完成注册或激活流程时，您应当按照法律法规要求，按相应页面的提示准确提供并及时更新您的资料，以使之真实、及时，完整和准确。如有合理理由怀疑您提供的资料错误、不实、过时或不完整的，
          ABoostify
          AI营销云平台有权向您发出询问及/或要求改正的通知，并有权直接做出删除相应资料的处理，直至中止、终止对您提供部分或全部服务。ABoostify
          AI营销云平台对此不承担任何责任，您将承担因此产生的任何直接或间接损失及不利后果。<br />
          您应当准确填写并及时更新您提供的电子邮件地址、联系电话、联系地址、邮政编码等联系方式，以便
          ABoostify
          AI营销云平台或其他会员与您进行有效联系，因通过这些联系方式无法与您取得联系，导致您在使用ABoostify
          AI营销云平台服务过程中产生任何损失或增加费用的，应由您完全独自承担。您了解并同意，您有义务保持您提供的联系方式的有效性，如有变更需要更新的，您应按ABoostify
          AI营销云平台的要求进行操作。<br />
          2.2 账户安全<br />
          2.2.1 您须自行负责对您的ABoostify
          AI营销云平台账户和密码保密，且须对您在该登录名和密码下发生的所有活动（包括但不限于信息披露、发布信息、网上点击同意或提交各类规则协议、网上续签协议或购买服务等）承担责任。
          您需确保您在每个上网时段结束时，以正确步骤离开网站。ABoostify
          AI营销云平台不能也不会对因您未能遵守本款规定而发生的任何损失或损毁负责。<br />
          2.2.2
          若您发现有他人盗用您的账号及密码、或任何其他未经您合法授权的情形时，您应立即以有效方式通知ABoostify
          AI营销云平台并提供必要资料（如客户资料、情况说明、证明材料及诉求等，以便ABoostify
          AI营销云平台核实身份及事件） 。ABoostify
          AI营销云平台收到您的有效通知并核实身份后，会依据法律法规及服务规则进行处理。ABoostify
          AI营销云平台依据本条进行处理产生的相关责任和后果由您承担。<br />
          2.2.3 若您提供的资料存在瑕疵，导致 ABoostify
          AI营销云平台无法核实您的身份或无法判断您的需求等，而导致ABoostify
          AI营销云平台未能及时处理，给您带来的损失，应由您自行承担。同时，您理解，ABoostify
          AI营销云平台对您的请求进行处理需要合理期限，对于ABoostify
          AI营销云平台采取措施前您已经产生的损失以及采取措施后因不可归责于ABoostify
          AI营销云平台的原因导致的损失，ABoostify
          AI营销云平台不承担任何责任。<br />
          2.2.4 您理解并同意，ABoostify
          AI营销云平台有权了解您使用本网站产品及服务的真实背景及目的，有权要求您如实提供真实、全面、准确的信息；如果ABoostify
          AI营销云平台有合理理由怀疑您提供的信息不真实、您进行虚假交易，或您的行为违反ABoostify
          AI营销云平台的网站规则的，ABoostify
          AI营销云平台有权暂时或永久限制您账户下所使用的所有产品及/或服务的部分或全部功能。<br />
          2.2.5 您理解并同意，基于运行和交易安全的需要，ABoostify
          AI营销云平台有权暂时停止或者限制您账号下部分或全部的资金支付功能
          ，ABoostify
          AI营销云平台将通过邮件、站内信、短信或电话等方式通知您，您应及时予以关注并按照程序进行申诉等后续操作。<br />
          2.2.6 您理解并同意，ABoostify
          AI营销云平台有权按照国家司法、行政、军事、安全等机关（包括但不限于公安机关、检察机关、法院、海关、税务机关、安全部门等）的要求对您的个人信息及在本网站的资金、交易及账户等进行查询、冻结或扣划。<br />
          2.3 账户注销<br />
          2.3.1 如您违反国家、地方法律法规规定或违反本服务条款的，ABoostify
          AI营销云平台有权中止或终止为您提供部分或全部服务、直至注销ABoostify
          AI营销云平台账户。<br />
          2.3.2 登录名的注销<br />
          （1）
          您理解并同意，如您连续12个月未使用您的邮箱、手机或本网站认可的其他方式和密码登录过本网站，且您的账户下不存在任何未到期的服务，ABoostify
          AI营销云平台有权注销您的登录名，您将不能再
          使用已注销的登录名登录ABoostify AI营销云平台网站。<br />
          （2）
          您同意并授权ABoostify网站，如您在任一ABoostify网站有欺诈、发布或销售伪劣商品、侵犯他人合法权益或其他严重违反任一ABoostify网站规则的行为，该网站在ABoostify网站的范围内对此有权披露，您的ABoostify
          AI营销云平台登录名可能被注销，被注销后您不能再登录ABoostify
          AI营销云平台网站，ABoostify AI营销云平台网站服务同时终止。<br />
          2.3.3 您保证不会采取任何影响、损害或可能影响、损害ABoostify
          AI营销云平台、ABoostify AI营销云平台的关联公司网站合法权益
          的行为；影响或损害ABoostify
          AI营销云平台网站合法权益的行为或方式包括但不限于：<br />
          （1） 违反ABoostify
          AI营销云平台公布的任何服务协议/条款、管理规范、交易规则等规范内容；<br />
          （2） 破坏或试图破坏ABoostify
          AI营销云平台公平交易环境或正常交易秩序；<br />
          （3） 任何使用含有ABoostify AI营销云平台名称、
          图标、品牌或任何对他人有误导嫌疑或任何使用某种中英文(全称或简称)、数字、域名等意图表示或映射与ABoostify公司具有某种关系的；<br />
          （4） ABoostify
          AI营销云平台根据自行合理判断，认为可能是与如上行为性质相同或产生如上类似风险的其他情况。<br />
          2.3.4 如您出现2
          .3项下行为或其他违反法律、行政法规、ABoostify平台规则等行为，您接受并认可ABoostify
          AI营销云平台有权在您违反上述约定时有权终止向您提供服务，即您将不能再登录ABoostify
          AI营销云平台网站，所有ABoostify AI营销云平台网站的服务将同时终止。<br />

          第三条 服务<br />
          3.1 服务开通<br />
          3.1.1
          您可根据自身需求，自行在线选购所需金蝉拓客服务套餐，购买前您需仔细阅读所购服务对应的服务规则并根据自身需求决定是否购买或使用。<br />
          3.1.2 ABoostify AI营销云平台将通过https://www.ABoostify.com
          网站的相关页面上向您列明金蝉拓客服务价格，或根据您选择服务的规格配置为您提供订购服务的应付费用，您应按照购买服务当时在https://www.ABoostify.com网站上的价格体系支付相应费用
          。支付成功后，即可使用您购买成功的产品，并可根据您的需要，决定是否签订电子合同，是否签订电子合同不影响您使用产品。<br />
          3.1.3
          部分金蝉拓客服务的开通可能需要双方另行签订单独的服务协议，单独的服务协议可能以电子文档形式展示，也可能是独立的纸质文档形式展示，您可以根据自身情况决定是否接受服务协议及开通服务。
          如无明确规定需要另行签订单独的服务协议，则已本协议为准。<br />
          3.1.4
          您需在订单提交后及时完成支付。部分服务可能有时间限制、库存限制或者活动数量等限制，订单提交后，若发生您未及时付款、或付款期间出现数量不足或已达到限量等情况的，您将可能无法购买并使用相关服务。<br />
          3.1.5
          服务激活后，由于您企业资质未满足相关政策要求导致的软件使用时长的消耗，由您自行承担。<br />
          3.1.6 金蝉拓客服务（包括其所有更新／升级版本）版权及所有权归属本站<br />
          3.2 服务费用<br />
          3.2.1
          付费：您可通过支付宝支付、微信支付、网上银行、账户余额等途径进行购买产品、产品续费、产品升级、产品降级等行为时支付产品费用，具体规则以ABoostify
          AI营销云平台相关页面展示为准。<br />
          3.2.2
          本协议项下的业务发生销售折让、销售退回或其他按照国家规定需要开具红字发票或重新开票的情况，ABoostify
          AI营销云平台需按照国家税收规定向您开具红字发票或重新开票，您需按照国家税收规定退回ABoostify
          AI营销云平台已开具的发票或向税局递交需ABoostify
          AI营销云平台开具红字专用发票的有效证明。双方约定具体按以下政策执行：<br />
          （1）
          如果退票发生在发票开具当月且您尚未进行税务认证抵扣的，您退回发票即可。<br />
          （2）
          如果您已就该发票进行税务认证抵扣，或者退票时间已超过开票日期所在当月的，您应向ABoostify
          AI营销云平台提供开具红字发票的必要资料，如《开具红字增值税专用发票信息表》等。<br />
          （3） 对于ABoostify
          AI营销云平台尚未开具发票或已开具发票但尚未交付给您的退款申请，无需您提供发票相关资料。<br />
          若无法满足上述条件，则无法办理退款，后果由您自行承担，ABoostify
          AI营销云平台不就此承担任何责任。在完成上述退票及开具红字发票流程后，ABoostify
          AI营销云平台将您账号内现金部分剩余款项进行退款。<br />
          3.3 服务支持<br />
          3.3.1 ABoostify
          AI营销云平台向您提供售后服务，协助您解答、处理使用ABoostify
          AI营销云平台服务过程中遇到的问题。<br />
          3.3.2 ABoostify
          AI营销云平台将依照法律法规要求对其系统、设备等采取基础的安全保护措施。若您对安全保护措施的要求高于前述基础的安全保护措施标准的，您有权根据自身需求购买配置更高的安全保护服务或另行配置其他安全防护软件、系统等。<br />
          3.3.3
          您应自行对您使用的计算机信息系统、设备等采取必要的、有效的安全保护措施，因您未采取前述措施导致权益受损的，由您自行承担责任。<br />
          3.3.4 ABoostify
          AI营销云平台在服务规则约定内提供可用性保障。如果您对可用性的要求高于服务规则，则需要您主动对自身系统进行高可用性的设置，ABoostify
          AI营销云平台可给与必要的协助。如果需要ABoostify
          AI营销云平台配合做设计，由双方另行协商确认。<br />
          3.3.5 ABoostify AI营销云平台是本站研发的一款SaaS平台，
          您只能通过ABoostify AI营销云平台提供的软件界面及账号进行操作和管理，
          本站无法向您提供网站源码及FTP，包含但不仅限于版本中建平台模块。<br />
          3.4.1
          合同期限至“服务周期”满止。“服务周期”即金蝉拓客服务为您发布视频的保留时间。自您签约之日起算，起算日最晚不超过ABoostify
          AI营销云平台收到您全部合同款后的第 60 天。 <br />
          3.4.2合同期限到达时，ABoostify
          AI营销云平台将提前三个月通知您续费，双方签订续费合同。如您无续费，ABoostify
          AI营销云平台有权收回所有推广资源，包括但不限于推广短视频渠道发布的信息。
          <br />
          3.4.3 协定期间，如出现下列情况之一的，您有权终止协议：<br />
          1）您逾期 10 个工作日未向ABoostify AI营销云平台支付到期款项的；<br />
          2）您违反本协议其他规定，且自ABoostify AI营销云平台通知其纠正之日起 10
          个工作日内仍未纠正的。 <br />
          3.4.4为了向您提供更加完善的服务， ABoostify
          AI营销云平台有权定期或不定期地对服务平台或相关设备、系统、软件等进行检修、维护、升级及优化等（统称“常规维护”），如因常规维护造成ABoostify
          AI营销云平台服务在合理时间内中断或暂停的，ABoostify
          AI营销云平台无需为此向您承担责任。但是，ABoostify
          AI营销云平台应当至少提前24小时，就常规维护事宜通知您。若因不可抗力、基础运营商过错等原因导致的非常规维护，ABoostify
          AI营销云平台应及时通知您 。<br />
          3.4.5 为保证服务的安全性和稳定性， ABoostify
          AI营销云平台可能进行机房迁移、设备更换等重大调整，前述情况可能导致ABoostify
          AI营销云平台服务在合理时间内中断或暂停，ABoostify
          AI营销云平台无需为此向您承担责任，但是，ABoostify
          AI营销云平台应提前30天通知您，您应予以配合；如您不配合进行调整，或者ABoostify
          AI营销云平台无法与您取得联系，由此产生的后果由您自行承担 。<br />
          3.4.6 ABoostify
          AI营销云平台有权根据自身运营安排，随时调整、终止部分或全部服务（包括但不限于对服务进行下线、迭代、整合等）。但是，ABoostify
          AI营销云平台应提前至少30天通知您，以便您做好相关数据的转移备份以及业务调整等，以保护您的合法权益。<br />
          3.4.7基于网络服务的及时性、复杂性、高效性等特性及监管要求、政策调整等原因，您同意
          ABoostify AI营销云平台可以不时对本协议以及ABoostify
          AI营销云平台的相关服务规则进行调整，并通过网站公告、邮件通知、短信通知、系统消息、站内信等方式中的一种或多种予以公布；若您在调整后继续使用ABoostify
          AI营销云平台服务的，表示您已充分阅读、理解并接受修改后的内容，也将遵循修改后的内容。<br />
          <br />
          3.5 第三方产品或服务<br />
          3.5.1 如果您通过ABoostify
          AI营销云平台获取、使用第三方提供的任何产品或服务（包括但不限于ABoostify
          AI营销云平台服务市场中由服务商等第三方提供的产品、服务），您应当自行评估该产品或服务是否符合您要求。<br />
          3.5.2
          第三方产品或服务的开通，可能需要您与第三方另行签订单独的协议，单独的协议可能以电子文档形式展示，也可能是独立的纸质文档，您可以根据自身情况决定是否接受协议及使用产品或服务。<br />
          3.5.3
          您因使用第三方产品或服务产生的纠纷由您与第三方自行解决，涉及的经济赔偿由您自行承担。<br />
          <br />
          第四条 客户权利义务<br />
          4.1 您保证：<br />
          （1）向ABoostify
          AI营销云平台提供的全部信息资料是真实、合法和正当的，未违反中国有关法律、法规的要求；
          <br />
          （2）及时更新所提供的信息资料；<br />
          （3）在支付合同款后五个工作日内向ABoostify
          AI营销云平台提供推广服务涉及的资料及公司经工商部门确认的营业执照复印件，其中电子文本限
          DOC、TXT 格式，电子图片限 BMP、JPG、GIF 格式。 <br />
          （4）您提供的资料之所有权及版权由您承诺任何因上述内容引起之争议均与ABoostify
          AI营销云平台无关，并自行承担相关责任； <br />
          （5）您需提供ABoostify
          AI营销云平台推广所需之相关企业资料以及对应素材、抖音账号等材料。并根据ABoostify
          AI营销云平台指导，完成对应套餐数量抖音账号注册以及蓝V认证等工作。<br />
          4.2 您有权依照本协议约定使用金蝉拓客服务并获得ABoostify
          AI营销云平台的技术支持和售后服务。<br />
          4.3 您在使用ABoostify
          AI营销云平台上的服务时须遵守相关法律法规及服务规则，并确保拥有法律法规要求的经营资质和能力，
          不得实施包括但不限于以下行为，也不得为任何违反法律法规的行为提供便利：<br />
          反对宪法所规定的基本原则的。<br />
          危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的。<br />
          损害国家荣誉和利益的。<br />
          煽动民族仇恨、民族歧视，破坏民族团结的。<br />
          破坏国家宗教政策，宣扬邪教和封建迷信的。<br />
          散布谣言，扰乱社会秩序，破坏社会稳定的。<br />
          散布淫秽、色情、赌博、暴力、凶杀、恐怖或教唆犯罪的。<br />
          侮辱或诽谤他人，侵害他人合法权益的。<br />
          实施任何违背“七条底线”的行为。<br />
          含有法律、行政法规禁止的其他内容的。<br />
          4.4 您在使用ABoostify AI营销云平台上的服务时须维护互联网秩序和安全，
          不得侵犯任何主体的合法权益，不得实施包括但不限于以下行为，也不得为其提供便利：<br />
          （1）
          实施欺诈、虚伪不实或误导行为，或实施侵害他人知识产权等任何合法权益的行为，如“私服”、“外挂”等。<br />
          （2）
          发布、传播垃圾邮件（SPAM）或包含危害国家秩序和安全、封建迷信、淫秽、色情、低俗等违法违规信息。<br />
          （3） 违反与ABoostify
          AI营销云平台网络相联通之网络、设备或服务的操作规定；实施违法或未授权之接取、盗用、干扰或监测。<br />
          （4）
          实施任何破坏或试图破坏网络安全的行为，包括但不限于以病毒、木马、恶意代码、钓鱼等方式，对网站、服务器进行恶意扫描、非法侵入系统、非法获取数据等。<br />
          （5） 实施任何改变或试图改变ABoostify
          AI营销云平台服务提供的系统配置或破坏系统安全的行为；利用技术或其他手段破坏、扰乱ABoostify
          AI营销云平台服务的运营或他人对ABoostify
          AI营销云平台服务的使用；以任何方式干扰或企图干扰ABoostify
          AI营销云平台任何产品或任何部分、功能的正常运行，或者制作、发布、传播上述工具、方法等。<br />
          （6） 因从事包括但不限于“DNS
          解析”、“安全服务”、“域名代理”、“反向代理”等任何业务，导致您自己频繁遭受攻击（包括但不限于
          DDoS 攻击）且未及时更正行为，或未根据ABoostify
          AI营销云平台要求消除影响，从而对ABoostify
          AI营销云平台服务平台或他人产生影响的。<br />
          （7） 实施其他破坏互联网秩序和安全的行为。<br />
          4.5
          您应当按照服务规则及时、足额支付费用。若您未及时、足额支付费用的，ABoostify
          AI营销云平台有权拒绝开通服务或无需另行通知而中止、终止服务，同时可以采取以下一项或多项措施：<br />
          （1）
          要求您除应依约支付应付费用外，每逾期一天，还应按所欠费用1‰的标准向ABoostify
          AI营销云平台支付违约金，直至缴清全部费用为止。<br />
          （2） 若您逾期付款超过15天，ABoostify
          AI营销云平台有权无需另行通知您即可随时采取包括但不限于提前单方解除协议的措施
          ，采取该措施后造成的一切法律后果及损失由您自行承担。<br />
          （3）
          若您逾期付款但您使用了预付费服务或您账号有未消耗的现金余额的，则ABoostify
          AI营销云平台有权无需另行通知您，而直接将预付费用和现金余额用于抵扣拖欠款项和违约金。<br />
          （4） 删除您基于使用ABoostify
          AI营销云平台服务而存储或产生的部分或全部信息和数据。<br />
          <br />
          第五条 ABoostify AI营销云平台权利义务<br />
          5.1
          您按照本合同的约定履行相应的义务后，有权获得本合同“服务项目”中所列之各项服务。ABoostify
          AI营销云平台须按合同约定向甲方提供各项服务，否则您有权利追究ABoostify
          AI营销云平台相关责任。 <br />
          5.2 ABoostify
          AI营销云平台保留对您发布信息的最终形式审核权，以及对ABoostify
          AI营销云平台短视频流程等进行调整的权利，但不得减少您已购买的服务项目。
          <br />
          5.3
          金蝉拓客为您提供的服务分为两个阶段。其中第一节阶段为协助您开通对应套餐数量的蓝V矩阵，并接入金蝉拓客视频发布系统开始发布视频。视频保持正常混剪和发布一个自然月时间，视为第一阶段交付，扣款40%。第二阶段为在合同剩余时间内，完成约定数量的视频发布和SEO服务。<br />
          5.4 因基础电信网络问题、ABoostify
          AI营销云平台例行的停机维护、升级工作而造成的服务中断，ABoostify
          AI营销云平台将顺延甲方被中断的服务时间。因不可抗力（如台风、地震、洪水、冰雹、战争、政府政策调整、社会事件、疫情管控、短视频平台规则调整等）造成金蝉拓客提供服务的中断，ABoostify
          AI营销云平台不承担违约责任。除前述情况外， ABoostify
          AI营销云平台仅对金蝉拓客本身提供运营维护，您应当保证自身的网络、设备的安全性、稳定性，如发生以下情况，您应及时解决并避免对金蝉拓客服务产生影响：<br />
          （1） 您内部网络出现问题，包括但不限于超负荷等。<br />
          （2） 您自有设备或您使用的第三方设备出现故障。<br />
          （3） 您自行拆离设备或通过其他方式导致网络中断。<br />
          （4） 其他您自身原因导致的任何故障、网络中断等。<br />
          5.5 若ABoostify
          AI营销云平台自行发现或根据相关部门的信息、权利人的投诉等发现您可能存在违反相关法律法规或本协议的行为的，ABoostify
          AI营销云平台有权根据一般人的认识自行独立判断，并随时单方采取以下措施中的一种或多种：<br />
          （1） 要求您立即删除、修改相关内容。<br />
          （2） 限制、暂停向您提供全部或部分ABoostify
          AI营销云平台服务（包括但不限于直接对您的部分服务进行下线并收回相关资源、对您账号采取操作限制/账号冻结等措施）。<br />
          （3） 终止向您提供ABoostify
          AI营销云平台服务，终止协议（包括但不限于直接对您的全部服务进行下线并收回相关资源等）。<br />
          （4） ABoostify
          AI营销云平台根据本协议终止向您提供服务或终止协议的，您预缴的费用将作为违约金归ABoostify
          AI营销云平台所有。<br />
          （5） 依法追究您的其他责任。<br />
          ABoostify
          AI营销云平台依据本协议采取相应措施（包括但不限于暂停服务、扣除费用、终止协议等），不视为ABoostify
          AI营销云平台违约，若因此给您造成损失（包括但不限于业务暂停、数据清空等）的，均由您自行承担。因您违反本协议约定产生的责任和后果由您自行承担，若给ABoostify
          AI营销云平台或第三方造成损失的，您应当予以赔偿。<br />
          5.6 为合理保护您、您的用户及权利人等各方的利益， ABoostify
          AI营销云平台有权制定专门的侵权、投诉流程制度，您应当予以遵守。如果ABoostify
          AI营销云平台接到第三方对您的投诉或举报，ABoostify
          AI营销云平台有权向第三方披露您相关资料（如您的主体资料及您就投诉或举报提交的反通知书、相关证据等全部资料），要求您与投诉方进行协商（包括但不限于建立包含您、ABoostify
          AI营销云平台以及投诉方的三方邮件组，供您、投诉方直接沟通、相互举证等，下同），若您投诉或举报其他ABoostify
          AI营销云平台客户的，ABoostify
          AI营销云平台也有权向被投诉方披露您相关资料（如您的主体资料及您就投诉或举报提交的通知书、相关证据等全部资料），要求您与被投诉方进行协商，以便及时解决投诉、纠纷，保护各方合法权益，您应当予以配合，否则，可能会影响您继续使用ABoostify
          AI营销云平台服务，由此造成您或他方损失的，您自行承担。<br />
          5.7 ABoostify
          AI营销云平台可将本协议部分或全部权利义务转让予第三方，但ABoostify
          AI营销云平台须提前90天书面通知您。为本款生效之需要，双方同意签订所有合理必要的文件及采取合理必要的措施。<br />
          5.8 您同意ABoostify
          AI营销云平台有权使用您的名称、品牌、商标标识等作为商业案例进行宣传、展示。<br />
          <br />
          第六条 客户数据<br />
          6.1 您保证，您存储、上传到ABoostify
          AI营销云平台服务中，或者利用ABoostify
          AI营销云平台服务以分析、分发等任何方式处理的数据，为您依法收集、获取或产生的数据，您不会也不曾以任何方式侵犯任何个人或实体的合法权利。<br />
          6.2 您保证其有权使用ABoostify
          AI营销云平台服务对前述数据进行存储、上传、分析和分发等任何处理，且前述处理活动均符合相关法律法规的要求，不存在任何违法、侵权或违反其与第三方的合同约定的情形，不会将数据用于违法违规目的。<br />
          6.3 您有权自行使用ABoostify
          AI营销云平台服务对数据进行上传、分析、删除、更改等处理（具体以您使用的服务规则为准），就数据的删除、更改等处理，您应谨慎操作并承担因该等操作所产生的后果。<br />
          6.4 除因您使用ABoostify
          AI营销云平台服务所需及法律法规另有规定外，未经您同意，ABoostify
          AI营销云平台不会接触或使用您的数据。<br />
          6.5 您应根据自身需求自行对数据进行备份，ABoostify
          AI营销云平台仅依据相关法律法规要求或服务规则约定提供数据备份服务，ABoostify
          AI营销云平台仅在法定或约定提供的数据备份服务的范围内承担责任。<br />
          6.6
          因您的数据的产生、收集、处理、使用等任何相关事项存在违反法律法规等情况而造成的全部结果及责任均由您自行承担，并且，ABoostify
          AI营销云平台有权随时全部或部分终止向您提供ABoostify
          AI营销云平台服务。您因此遭受的一切损失或承担的一切责任和费用，由您自行承担。<br />
          <br />
          第七条 知识产权<br />
          7.1
          双方各自享有的商标权、著作权或者其他知识产权，均仍归各方单独享有，并不会因为双方签订或者履行本协议而转归对方享有，或者转归双方共同享有。<br />
          7.2
          任何一方均应尊重相对方、第三方的知识产权，如有任何第三方就一方侵犯其知识产权等合法权益的原因向另一方提起诉讼、主张索赔的，责任方应当独立处理相关纠纷，赔偿另一方的直接经济损失，并使另一方免责。<br />
          <br />
          第八条 保密信息<br />
          8.1
          双方为了本协议目的，已经或将会提供或透露某些保密信息。其中，披露保密信息的一方为“披露方”，而接受保密信息的一方为“接收方”。<br />
          8.2
          “保密信息”指由披露方持有的与其业务、经营、技术及权利等事项相关的，非公开的信息、资讯、数据、资料等，包括但不限于营业规划，商务资料，与技术有关之知识及信息、创意、设想、方案，提供的物品或厂商资料，用户信息，人事资料，商业计划，服务价格及折扣，财务状况等。<br />
          8.3
          接收方从披露方所获悉的信息，如有标明或可合理认知为属于披露方的保密信息的，接收方须对披露方的保密信息保密，未经披露方书面同意，不得以任何方式披露给第三方或用于本协议以外目的。接收方应以对待其自身保密信息相同的注意程度（且不得低于合理审慎义务）对待披露方的保密信息。<br />
          8.4 尽管有前述约定，符合下列情形之一的，相关信息不被视为保密信息：<br />
          （1）
          接收方在披露方向其披露之前已经通过合法的渠道或方式持有的信息。<br />
          （2）
          该信息已经属于公知领域，或该信息在非因接收方违反本协议的情况下而被公开。<br />
          （3）
          接收方合法自其他有权披露资料的第三方处知悉且不负有保密义务的信息。<br />
          （4）
          由接收方不使用或不参考任何披露方的保密信息而独立获得或开发的。<br />
          8.5
          如果接收方基于法律法规或监管机关的要求，需要依法披露方的保密信息的，不视为违反本协议，但接收方应当在法律许可的范围内尽快通知披露方，同时，接收方应当努力帮助披露方有效限制该保密信息的披露范围，保护披露方合法权益。<br />
          8.6
          双方保密义务在本协议有效期限内及期限届满后持续有效，直至相关信息不再具有保密意义。<br />
          8.7
          一旦发生保密信息泄露事件，双方应合作采取一切合理措施避免或者减轻损害后果的产生；如因接收方违反保密义务给披露方造成损失的，接收方应赔偿因此给披露方造成的直接经济损失。<br />
          8.8 ABoostify AI营销云平台会按照公布的《ABoostify
          AI营销云平台隐私声明》的规定保护您的相关信息。<br />
          <br />
          第九条 责任限制<br />
          9.1 您理解并同意，在使用ABoostify
          AI营销云平台服务的过程中可能会遇到以下情况使服务发生中断。出现下述情况时，ABoostify
          AI营销云平台应及时与相关单位配合进行修复，但是由此给您造成的损失ABoostify
          AI营销云平台将予以免责。<br />
          （1）
          不可抗力，包括但不限于自然灾害、政府行为、法律法规颁布调整、罢工（任一方内部劳资纠纷除外）、动乱等不能预见、不能避免并不能克服的客观情况。<br />
          （2）
          基础运营商原因，包括但不限于电信部门技术调整、电信/电力线路被他人破坏、电信/电力部门对电信网络/电力资源进行安装、改造、维护。<br />
          （3）
          网络安全事故，如计算机病毒、木马或其他恶意程序、黑客攻击的破坏。<br />
          （4） 您通过非ABoostify AI营销云平台授权的方式使用ABoostify
          AI营销云平台服务，您操作不当或您的电脑软件、系统、硬件和通信线路出现故障。<br />
          （5） 其他非ABoostify AI营销云平台过错、ABoostify
          AI营销云平台无法控制或合理预见的情形。<br />
          9.2
          因不可抗力、基础运营商原因、网络安全事故或其他超出当事人可合理掌控范围的事件，造成本协议迟延履行或任何一方违约，双方均无需承担违约责任。但是，受影响一方应尽可能及时通知另一方。如前述事件妨碍协议履行达30天以上的，任一方可提前15天书面通知对方终止协议。因本条款终止协议的，任何一方均无须承担违约责任。<br />
          9.3 您理解并同意， ABoostify
          AI营销云平台的服务是按照现有技术和条件所能达到的现状提供的。ABoostify
          AI营销云平台将尽最大努力确保服务的连贯性和安全性，但ABoostify
          AI营销云平台不能保证其所提供的服务毫无瑕疵，因此，即使ABoostify
          AI营销云平台提供的服务存在瑕疵，但若上述瑕疵是当时行业技术水平所无法避免的，其将不视为ABoostify
          AI营销云平台违约，ABoostify
          AI营销云平台也无需承担任何责任，双方应友好协作共同解决问题。<br />
          9.4
          于任何情形下，任一方均不对另一方之任何间接的、偶然的、特殊的或惩罚性的损害和损失（如利润损失、机会损失、向第三方支付的费用、声誉/商誉损失或损害等）承担责任，无论基于合同、保证、侵权或任何其他责任理论，不论任一方是否知道或应当知道上述损失或损害的可能性。<br />
          9.5 于任何情形下， ABoostify
          AI营销云平台基于本协议及相关订单和ABoostify
          AI营销云平台服务向您所承担的赔偿责任（无论基于合同、侵权或任何其他责任理论）的总额，不超过您就造成您损失的ABoostify
          AI营销云平台服务已经向ABoostify
          AI营销云平台支付的费用总额；如果造成您损失的相应ABoostify
          AI营销云平台服务的服务期限超过12个月的，则ABoostify
          AI营销云平台的最大赔偿责任不超过损害发生前12个月，您就造成您损失的ABoostify
          AI营销云平台服务已经向ABoostify
          AI营销云平台支付的费用总额（为免歧义，本处费用指您就实际使用该服务的时长/数量等而实际已支付的现金，不包括代金券、预付但未实际消耗的费用等）。若本条款与双方其他约定或ABoostify
          AI营销云平台服务规则冲突或不一致的，以本条约定为准。<br />
          <br />
          第十条 通知和送达<br />
          10.1
          您应当保证和维持客户资料（包括但不限于电话号码、电子邮箱等联系方式）的有效性，客户资料若存在虚假、无效等任何可能导致您无法及时获悉业务通知、服务提示、客户服务、技术支持、纠纷协调、违规处罚等信息的，由您自行承担相应责任。<br />
          10.2 您应当根据ABoostify AI营销云平台官网公布的ABoostify
          AI营销云平台的联系方式向ABoostify
          AI营销云平台发送通知，双方另有约定除外。<br />
          10.3 ABoostify
          AI营销云平台可通过网页公告、系统通知、站内信、电子邮件、手机短信、即时通讯工具、函件等方式中的一种或多种向您发送与ABoostify
          AI营销云平台服务有关的业务通知、服务提示、验证消息、营销信息等各种信息（包括但不限于更新后的服务规则、服务升级、机房裁撤、广告等）。前述信息在以下情况下视为已送达：<br />
          （1） 交专人递送的，在收件人签收时视为已送达。<br />
          （2） 以网页公告等形式公布的，一经公布即生效（另有说明除外）。<br />
          （3）
          以电子形式（包括系统通知、站内信、电子邮件、手机短信、即时通讯工具等）发送的，在发送成功后视为已送达。<br />
          （4）
          以邮资预付的快递公司递送或以挂号信递送的，以投邮后的第3个自然日视为已送达。<br />
          如果送达时间为法定节假日的，则以送达后首个工作日为送达日。<br />
          <br />
          第十一条 法律适用及争议解决<br />
          11.1
          本协议的成立、生效、履行、解释及纠纷解决等，均适用中国大陆法律法规（不包括冲突法）。<br />
          11.2
          因本协议产生之争议，双方应首先协商解决；双方未能协商解决的，任何一方均应将争议提交平台所在地的人民法院裁决。双方无争议的部分，应当继续履行。<br />
          <br />
          第十二条 其他<br />
          12.1
          除非另有说明，本协议正文及其附件中所有的“天”均为自然日，结算货币均为人民币。<br />
          12.2
          本协议的拟定、解释均以中文为准。除双方另有约定外，任何有关本协议的翻译不得作为解释本协议或判定双方当事人意图之依据。<br />
          1.3
          服务规则为本协议的重要组成部分，与本协议正文具有同等法律效力，您应当予以遵守。（正文完）<br />
        </div>
      </div>
      <div class="sign-tip">
        说明：点击“签署合同”会跳转至第三方“e签宝平台”在线签署合同
      </div>
      <div class="sign-btn" @click="handleSave">我已阅读并同意条款</div>
      <div class="sign-close" @click="shows = false">
        <img src="../../assets/icon/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../../utils/request";

const URL = {
  buy: "api/customer/userProduct/buy",
};
export default {
  props: {
    show: Boolean,
    data: {
      type: Object,
    },
  },
  computed: {
    shows: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit("update:show", newValue);
      },
    },
  },
  methods: {
    async handleSave() {
      try {
        await HTTP({
          url: URL.buy,
          method: "post",
          data: {
            productId: this.data.id,
          },
        });
        this.shows = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sign-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  .sign {
    width: calc(516px + 162px);
    height: calc(430px + 72px);
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    &-text {
      font-size: 30px;
      color: #000000;
      text-align: center;
      margin-top: 35px;
    }
    &-tag {
      font-size: 16px;
      font-family: SourceHanSansCN-Bold-, SourceHanSansCN-Bold;
      font-weight: normal;
      color: #3b3b3b;
      line-height: 24px;
      padding-left: 27px;
      margin-top: 27px;
      span {
        color: #4e6bef;
        font-weight: bold;
      }
    }
    &-content {
      width: calc(516px + 162px - 54px);
      height: 220px;
      overflow: hidden;
      margin: 15px auto;
      background: #f8faff;
      border: 1px solid #d9e0ed;
      overflow-y: auto;
      div {
        padding: 20px;
        font-size: 13px;
        color: #3b3b3b;
        line-height: 22px;
      }
    }
    &-tip {
      font-size: 14px;
      color: #7c7f97;
      padding-left: 27px;
      line-height: 24px;
    }
    &-btn {
      width: 272px;
      height: 37px;
      background: #4e6bef;
      border-radius: 8px;
      margin: auto {
        top: 25px;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
    }
    &-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
